<template>
    <div class="comp-user-container">
        <header class="header">
            <div class="title">个人中心</div>
        </header>
        <a-row :gutter="16">
            <a-col :span="14">
                <a-card >
                    <div v-if="user.isPro">恭喜！你已开通闪题会员。（PC版和微信小程序版都是会员噢！）</div>
                    <div v-else>你还不是会员：{{user.coin}} <a-button type="primary" size="small" @click="isShowPayDrawer = true">立即开通</a-button></div>
                    <!-- <div>昵称：{{user.nickName}}</div> -->
                </a-card>
            </a-col>
            <a-col :span="10">
                <!-- <a-list bordered :locale="{emptyText: '暂无硬币记录'}" style="background: #fff;">
                    <a-list-item v-for="item of recordList" :key="item._id">{{ item.type === 'recharge' ? '充值' : '消费'}} {{item.coin}} 硬币</a-list-item>
                </a-list> -->
            </a-col>
        </a-row>
        <a-modal title="充值硬币" v-model:visible="isShowPayDrawer" :footer="null" :bodyStyle="{padding: 0}" :width="600" :maskClosable="false" >
            <PayCode @close="isShowPayDrawer = false;" @update="refresh"/>
        </a-modal>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, inject, onMounted } from 'vue';
import Api from '@/api/index';
import PayCode from './PayCode';
export default {
    components: { PayCode },
    setup() {
        const { user, updateUser } = inject('user')
        const isShowPayDrawer = ref(false);
        
        const router = useRouter();
        const recordList = ref([]);
        
        const refresh = () => {
            updateUser();
            Api.uniModel('coin_record').findAllBy({ openid: user.openid }).then(res => {
                console.log('res', res);
                recordList.value = res;
            })
        }
        onMounted(() => refresh())

        if (!user.openid) {
            router.push('/login');
        }
        return { user, isShowPayDrawer, refresh, recordList };
    }
}
</script>
<style lang="less" scoped>
.comp-user-container {
    height: 100vh;
    padding: 24px;
    .header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        // border-bottom: 1px solid #f0f0f0;
        padding-bottom: 16px;
    }
}
</style>