<style lang="less" scoped>
.pay-code-container {
    position: relative;
    overflow: hidden;
    padding: 24px;
    height: 320px;
}
.shops {
    display: flex;
    .shop-item {
        flex: 1;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #f0f0f0;
        height: 160px;
        .money {
            font-size: 24px;
            color: cornflowerblue;
        }
        &.active {
            background: cornflowerblue;
            border-color: cornflowerblue;
            color: #fff;
            .money { color: #fff; }
        }
    }
}
</style>
<template>
    <main class="pay-code-container">
        <section class="shops">
            <div :class="'shop-item ' + (i === curShopIndex ? 'active' : '')" v-for="(shop, i) of shops" :key="i" @click="curShopIndex = i">
                <div class="money">¥{{ shop.money }}</div>
                <div style="margin-top: 12px;">开通会员</div>
                <!-- <div style="margin-top: 12px;">{{ shop.coin }} 硬币</div> -->
            </div>
        </section>
        <div style="margin-top: 60px; text-align: center;">
            <a-button @click="payInfo.isShowPayCode = true" type="dashed" >点此微信扫码支付</a-button>
        </div>
        <a-drawer v-model:visible="payInfo.isShowPayCode" placement="bottom" :get-container="false" :wrap-style="{ position: 'absolute' }" :closable="false" :height="320">
            <div style="text-align: center; margin: 30px auto 0;">
                <a-spin :spinning="payInfo.isLoading">
                    <img :src="payInfo.payImg" alt="" style="width: 160px; height: 160px;">
                </a-spin>
                <div style="margin-top: 10px;">扫码支付 {{shops[curShopIndex].money}} 元</div>
                <a-row style="width: 50%; margin: 20px auto 0;">
                    <a-col :span="12">
                        <a-button type="primary" @click="paySuccessBtn">支付完成</a-button>
                    </a-col>
                    <a-col :span="12">
                        <a-button type="dashed" @click="payInfo.isShowPayCode = false;">关闭</a-button>
                    </a-col>
                </a-row>
            </div>
        </a-drawer>
    </main>
</template>

<script>
import Api from '@/api/index';
import { ref, reactive, watchEffect, inject } from 'vue';
export default {
    props: {
        total_fee: Number,
        coin: Number,
    },
    setup(props, { emit }) {
        const { user } = inject('user');
        const shops = ref([
            {coin: 1, money: 18, gift: 0},
            // {coin: 100, money: 10, gift: 0},
            // {coin: 500, money: 50, gift: 100},
            // {coin: 1000, money: 100, gift: 100},
        ])
        const curShopIndex = ref(0);
        const payInfo = reactive({
            isShowPayCode: false,
            isLoading: false,
            payImg: ''
        });
        watchEffect(() => {
            if (payInfo.isShowPayCode) {
                getPayImage();
            }
        })
        const getPayImage = () => {
            payInfo.isLoading = true;
            Api.uniModel('pay').req('GET_PAY_IMG', { openid: user.openid, total_fee: shops.value[curShopIndex.value].money * 100 }).then(res => {
                console.log(res);
                payInfo.payImg = res.qrcode;
                payInfo.isLoading = false;
            }).catch(err => {
                payInfo.isLoading = false;
                console.log('err', err);
            })
        }

        const paySuccessBtn = () => {
            payInfo.isShowPayCode = false; 
            emit('close')
            emit('update')
        }

        return { shops, curShopIndex, getPayImage, payInfo, paySuccessBtn }
    }
}
</script>
